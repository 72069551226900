import React, { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import styles from './music.module.css';

const AudioPlayer = ({ audioSrc, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1); // Default volume is 1 (max)
  const audioRef = useRef(null);
  const progressRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateProgress = () => {
        setCurrentTime(audio.currentTime);
        setDuration(audio.duration);
      };
      audio.addEventListener('timeupdate', updateProgress);

      return () => {
        audio.removeEventListener('timeupdate', updateProgress);
      };
    }
  }, []);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleScrub = (event) => {
    const scrubTime = (event.target.value / 100) * duration;
    audioRef.current.currentTime = scrubTime;
    setCurrentTime(scrubTime);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value / 100;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  return (
    <div style={{ marginBottom: '60px' }}> {/* Increased the margin between tracks */}
      <h4 style={{ marginBottom: '20px' }}>{title}</h4> {/* Added margin for the title */}
      <audio ref={audioRef} src={audioSrc} onLoadedMetadata={() => setDuration(audioRef.current.duration)} />

      {/* Play/Pause button */}
      <button onClick={togglePlay}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>

      {/* Playbar (Scrub Slider) */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{formatTime(currentTime)}</span>
        <input
          ref={progressRef}
          type="range"
          min="0"
          max="100"
          value={(currentTime / duration) * 100 || 0}
          onChange={handleScrub}
          style={{ flexGrow: 1, margin: '0 10px' }}
        />
        <span>{formatTime(duration)}</span>
      </div>

      {/* Volume Slider */}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', flexDirection: 'column' }}>
        <label htmlFor="volume" style={{ fontWeight: 'bold'}}>Volume</label>
        <input
          type="range"
          id="volume"
          min="0"
          max="100"
          value={volume * 100}
          onChange={handleVolumeChange}
          style={{ marginLeft: '10px' }}
        />
      </div>
    </div>
  );
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const MultipleAudioPlayers = () => {
  const tracks = [
    { title: 'Track 01: "Cloudy Sky"', src: 'https://www.dropbox.com/scl/fi/1knys1qltyf254wkpr913/track01.mp3?rlkey=fogwvbpf91leviw39kqhfe70l&st=c2dj2a15&dl=1' },
    { title: 'Track 02: "Hurricane Watch"', src: 'https://www.dropbox.com/scl/fi/cwnphq80tezuob98vtgmm/Sdslayer100-Track-02.mp3?rlkey=ecimp4k1hpw3gdyrrn0i773n9&st=ft2gr76l&dl=1' },
    { title: 'Track 03: "Countdown to Destruction"', src: 'https://www.dropbox.com/scl/fi/9uifcqd51esw2h9rfmryb/Track-03.mp3?rlkey=trr6mcr7vg3ihbqz87nokfxsq&st=jn1sotlp&dl=1' },
    { title: 'Track 04: "Overcast"', src: 'https://www.dropbox.com/scl/fi/axx9f78ipme9m7pimjmgi/Track-04-v2022-A.mp3?rlkey=q4bfeuh3d57m9v4rukfsb86ol&st=ut7upxnk&dl=1' },
    { title: 'Track 05: "Storm Surge"', src: 'https://www.dropbox.com/scl/fi/1uvt3d5a38iwrh7dmiwsj/Track-05-backup.mp3?rlkey=k6sgr9vqzubiqqyd8ew85q2fx&st=6xfq94t2&dl=1' },
    { title: 'Track 06: "The Day After Landfall"', src: 'https://www.dropbox.com/scl/fi/jimhoiwijl64dr6wmtcwd/Track-06.mp3?rlkey=gxk3kn1ilpmuzn3ul0a3zct4y&st=2ujeqitk&dl=1' },
    { title: 'Track 07: "Signal Five"', src: 'https://www.dropbox.com/scl/fi/hdz0c50ph53iz6gfyindj/Signal-Five.mp3?rlkey=q1sgn8x8mobu7dxpp5gg4xcqi&st=7xy5f2p2&dl=1' },
    { title: 'Track 08: "Iota"', src: 'https://www.dropbox.com/scl/fi/sje658c9l6ci6c4ze226c/spotifydown.com-Iota.mp3?rlkey=551ayjhljmixzpu1hhfpo0kwv&st=ebiahkjw&dl=1' },
    { title: 'Track 09: "Landfall"', src: 'https://www.dropbox.com/scl/fi/3i70ikc3sov1bs3wjcp21/Landfall-hurricane-ver.mp3?rlkey=9vq3x2m0796zamfhmd7rqpkl6&st=1jqubkis&dl=1' },
    { title: 'Track 10: "One5Seven"', src: 'https://www.dropbox.com/scl/fi/93mxa8b5ni2ir71swmuq7/One5Seven.mp3?rlkey=6nqley82japr1u2xvg2iu0gom&st=ti3v25by&dl=1' },
    { title: 'Track 11: "Show Up!"', src: 'https://www.dropbox.com/scl/fi/hi5ywfuhnw5u4cd02x22t/Track-11.mp3?rlkey=8y33f08dt2w1s66ebj1x7halc&st=yc8tzhyj&dl=1' },
    { title: 'Track 12: "11:01"', src: 'https://www.dropbox.com/scl/fi/v69syuf6xq4s3mtyx4o5o/Track-12.mp3?rlkey=3pihrcmwbkxllfbozso77h9bt&st=jt7nhb76&dl=1' },
    { title: 'Track 13: "Terminal Four"', src: 'https://www.dropbox.com/scl/fi/wtdp99qzdy2ull09wxmiq/Track-13-idk-who-cares.mp3?rlkey=y2qpjn7uuhj0c6jicmb3powaa&st=t2pchh91&dl=1' },
    { title: 'Track 14: "Twelve2One"', src: 'https://www.dropbox.com/scl/fi/alciao178rcvyes4xp2ts/Twelve2One.mp3?rlkey=n1hcrjqzkcqa3w9e45srpeaph&st=b1n80tso&dl=1' },
    { title: 'Track 15: "Entry Point"', src: 'https://www.dropbox.com/scl/fi/46swiw4j4yfgc1rus5ntc/Track-15.mp3?rlkey=zf1rbwjtyi4n9l5ttnlpm85s9&st=vx9iod0n&dl=1' },
    { title: 'Track 16: "System Check"', src: 'https://www.dropbox.com/scl/fi/v7suo5729e6ymaueemt9s/track-16-2.mp3?rlkey=kytxkwy3yyz72nkra3bffcfyd&st=0s1lw67p&dl=1' },
  ];

  return (
    <div>
      {tracks.map((track, index) => (
        <AudioPlayer key={index} audioSrc={track.src} title={track.title} />
      ))}
    </div>
  );
};


const SecondAudioPlayer = () => {
    const tracks = [
      { title: '"Dash All Night"', src: 'https://www.dropbox.com/scl/fi/kvuy3vrakq12xucksn02l/dashallnight.mp3?rlkey=y6um8eh6xbfb2uha2f6f2jgy5&st=u53geahe&dl=1' },
      { title: 'Track 10b: "One5Seven Part II"', src: 'https://www.dropbox.com/scl/fi/6azknkra9pnws3ga7eodp/One5Seven-Part-II-2024.mp3?rlkey=n7aesj1ouw3hqskrtqqin224k&st=6qs99kxz&dl=1' },
      { title: 'Track 10c: "One5Seven Part III"', src: 'https://www.dropbox.com/scl/fi/hdgid87zovrhbibpzwaaz/One5Seven-Part-III-2-1.mp3?rlkey=0osp9617soiyp2r3qm3hduxxp&st=54cn57qz&dl=1' },
      { title: 'Track 02 v2024: "Hurricane Watch v2024"', src: 'https://www.dropbox.com/scl/fi/o70ymmvqyozendf84ms4l/Track-02-rm-2.mp3?rlkey=bpu9fez0ueq0vqnt9mkmuj29z&st=g144wuse&dl=1' },
    ];
  
    return (
      <div>
        {tracks.map((track, index) => (
          <AudioPlayer key={index} audioSrc={track.src} title={track.title} />
        ))}
      </div>
    );
  };

export const Music = () => {
    // const navigate = useNavigate();
    
    return (
        <div className={styles["home-page"]}>
            <div className={styles["content"]}>
                <h1 className={styles["fade-text-1"]}>I make music sometimes</h1>
                <br></br>
                <h2>Main Tracks</h2>
                <MultipleAudioPlayers />

                <h2>Misc. Tracks</h2>
                <SecondAudioPlayer />
            </div>

            
            <a href="../" className={styles["home-page-text"]}><p>Home Page</p></a>
        </div>
    );
};
